@tailwind base;
@tailwind components;
@tailwind utilities;



@media (min-width: 1000px)  {
#desktop{
  display: block;
}
#navPop{
  z-index: 1001;

  width: 100%;
  height: 100%;
  margin: auto;
  position: fixed;
  top: 0%;
  left: 0%;
  display: flex;
  justify-content: end;
background-color: rgba(0, 0, 0,0.75);
}
#tablet,#mobile{
  display: none;
}
  .adminBody{
    position: absolute;
    left: 20%;
  
    width: 80%;
    
  }
  .image-container {
    display: flex;
    overflow-x: hidden;
  }
  
  .image-container img {
    flex-shrink: 0;
    width: 100%;
    opacity: 0.5;
    transition: opacity 0.3s ease-in-out;
  }
  
  .image-container img.active {
    opacity: 1;
  }
  .header{
    font-family: "Poetsen One", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  .par{
    font-family: "Quicksand", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
  }
  
  .adminNav{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vw;
    width: 20%;
  }
  
  .report-card{
    width: 200px;
    border-radius: 20px;
    background-color: #D9FE00;
  
   
  }
  .skillClass{
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 10px;
  padding-right: 10px;
  
  }
  .seymour-one-regular {
    font-family: "Anton", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
  }
  .orderPop{
    overflow-y: scroll;
    position: fixed;
    top: 10vh;
    right:0 ;
    width: 90%;
    height: 90vh;
    background-color: white;
    z-index: 100;
    border-top-left-radius: 40px;
  }
  .bg-black{
  background-color: rgba(0, 0, 0,0.8);


}
  .logoAdmin{
    width: 50%;
    margin-top: 20px;
  }
  
  .your-component .react-colorful {
    height: 240px;
  }
  .your-component .react-colorful__saturation {
    border-radius: 4px 4px 0 0;
  }
  .your-component .react-colorful__hue {
    height: 40px;
    border-radius: 0 0 4px 4px;
  }
  .your-component .react-colorful__hue-pointer {
    width: 12px;
    height: inherit;
    border-radius: 0;
  }
  .skillGrid{
    display: grid;
    grid-template-columns: auto  auto auto;
    column-gap: 5px;
    row-gap: 10px;
    justify-content: center;
    text-align: center; 
    word-break: keep-all;
    margin-top: 15px;
    align-items: center;
    background-color: #D9FE00;
    color: black;
  
  }
  .navigationPop{
  z-index: 1001;
    
    width: 100%;
    height: 100vw;
    margin: auto;
    position: fixed;
    top: 0%;
    left: 0%;
  background-color: rgba(0, 0, 0,0.75);
  }
  .IngredientsSkills{
    display: grid;
    grid-template-columns: auto  auto auto auto auto auto;
    column-gap: 5px;
    row-gap: 10px;
    word-break: keep-all;
    margin-top: 10px;
    align-items: center;
  }
  .input-field-signUp:-webkit-autofill {
    caret-color: black;
    -webkit-box-shadow: 0 0 0 30px white inset; /* Override the autofill background color */
  }
  .input-field-signUp::placeholder {
    background-color: transparent; /* Set the background color of the placeholder text to transparent */
  }
  .barlow{
    font-family: "Barlow Condensed", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
  }
  .success{
    background-color: #00ff59;
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  
  
  }
  .successCourseProgram{
    background-color: #00ff59;
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #deleteModal,#deleteProfile,.signInpop{
    width: 100%;
    height: 100vw;
    margin: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 100;
  transform: translate(-50%,-50%);
  background-color: rgba(0, 0, 0,0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  }
  .cakeGrid{
    display: grid;
    grid-template-columns: 28% 28% 28%;
    column-gap: 40px;
    row-gap:70px;
    justify-content: center;
    text-align: center; 
    word-break: keep-all;
    margin-top: 15px;
    align-items: center;
    margin-bottom:70px;
  }
  .form-control{
  }
  .logo{
    width: 50%;
    margin-top: 20px;
    margin-bottom: 60px;
    }
  .report-card-text{
    font-family: "Reddit Mono", monospace;
    font-optical-sizing: auto;
    font-weight: 800;
    font-style: normal;
  }
  
  .report-card-text{
    font-family: "Reddit Mono", monospace;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
  }
  
  .nav-bg-yellow{
    background-color: #D9FE00;
    display: flex;
    align-items: center;
  }
  .logo{
  width: 13%;
  margin-left: 20px;
  cursor: pointer;
  }
  
  .nav{
    display: flex;
    justify-content: space-between;
  }
  
  .nosifer {
    font-family: "Nosifer", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 60px;
    line-height: 63px;
  }
  .paymentPop{
    width: 100%;
    height: 100vw;
    margin: auto;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 100;
  transform: translate(-50%,-50%);
  background-color: rgba(0, 0, 0,0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  }
  .nav__icon {
    position: relative;
    -ms-flex: 0 0 30px;
    flex: 0 0 30px;
    width: 30px;
    height: 30px;
  width: 30px;
      height: 30px;
      margin: 0 0 0 20px;
      color: black;
      will-change: color;
      transition: color .3s ease;
      cursor: pointer;
      background: 0 0;
      border: none;}
  
      .nav__cart-count {
        font-variant-ligatures: common-ligatures;
        font-feature-settings: "liga" 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-family: National2Web-Regular, helvetica, sans-serif;
        font-weight: 700;
        font-style: normal;
        font-stretch: normal;
        font-size: .625rem;
        line-height: 1.1;
        letter-spacing: normal;
        margin: 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        position: relative;
        z-index: 1;
        top: 7px;
        color: #09009a;
        border-radius: 20px;
        padding: 1px 0;
        z-index: 100;
        min-width: 14px;
        text-align: center;
        opacity: 0;
        transition: opacity .3s ease, background-color .3s ease, color .3s ease;
    }
  
      .nav__icon--cart {
      
        width: 25px;
        height: 25px;
    }
  .nav-Text{
    font-family: "Cabin Condensed", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 11px;
  }
  
  
}


@media (min-width: 500px) and (max-width: 1000px) {

  #tablet{
    display: block;
  }
  .fullHeight{
   height: 100vh;
  }
  #navPop{
  z-index: 1001;

    width: 100%;
    height: 100%;
    margin: auto;
    position: fixed;
    top: 0%;
    left: 0%;
    display: flex;
    justify-content: end;
  background-color: rgba(0, 0, 0,0.75);
  }
  
  #desktop,#mobile{
    display: none;
  }
    .adminBody{
      position: absolute;
      left: 20%;
    
      width: 80%;
      
    }
    .image-container {
      display: flex;
      overflow-x: hidden;
    }
    
    .image-container img {
      flex-shrink: 0;
      width: 100%;
      opacity: 0.5;
      transition: opacity 0.3s ease-in-out;
    }
    
    .image-container img.active {
      opacity: 1;
    }
    .header{
      font-family: "Poetsen One", sans-serif;
      font-weight: 400;
      font-style: normal;
    }
    .par{
      font-family: "Quicksand", sans-serif;
      font-optical-sizing: auto;
      font-weight: 500;
      font-style: normal;
    }
    
    .adminNav{
      position: fixed;
      top: 0;
      left: 0;
      height: 100vw;
      width: 20%;
    }
    
    .report-card{
      width: 200px;
      border-radius: 20px;
      background-color: #D9FE00;
    
     
    }
    .skillClass{
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 10px;
    padding-right: 10px;
    
    }
    .seymour-one-regular {
      font-family: "Anton", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 20px;
    }
    .orderPop{
      overflow-y: scroll;
      position: fixed;
      top: 10vh;
      right:0 ;
    width: 100%;

      height: 90vh;
      background-color: white;
      z-index: 100;
      border-top-left-radius: 40px;
    }
    .bg-black{
  background-color: rgba(0, 0, 0,0.8);


}
    .logoAdmin{
      width: 50%;
      margin-top: 20px;
    }
    
    .your-component .react-colorful {
      height: 240px;
    }
    .your-component .react-colorful__saturation {
      border-radius: 4px 4px 0 0;
    }
    .your-component .react-colorful__hue {
      height: 40px;
      border-radius: 0 0 4px 4px;
    }
    .your-component .react-colorful__hue-pointer {
      width: 12px;
      height: inherit;
      border-radius: 0;
    }
    .skillGrid{
      display: grid;
      grid-template-columns: auto  auto auto;
      column-gap: 5px;
      row-gap: 10px;
      justify-content: center;
      text-align: center; 
      word-break: keep-all;
      margin-top: 15px;
      align-items: center;
      background-color: #D9FE00;
      color: black;
    
    }
    .navigationPop{
  z-index: 1001;
      
      width: 100%;
            min-height: 100vh;

      margin: auto;
      position: fixed;
      top: 0%;
      left: 0%;
    background-color: rgba(0, 0, 0,0.75);
    }
    .IngredientsSkills{
      display: grid;
      grid-template-columns: auto  auto auto auto auto auto;
      column-gap: 5px;
      row-gap: 10px;
      word-break: keep-all;
      margin-top: 10px;
      align-items: center;
    }
    .input-field-signUp:-webkit-autofill {
      caret-color: black;
      -webkit-box-shadow: 0 0 0 30px white inset; /* Override the autofill background color */
    }
    .input-field-signUp::placeholder {
      background-color: transparent; /* Set the background color of the placeholder text to transparent */
    }
    .barlow{
      font-family: "Barlow Condensed", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 13px;
    }
    .success{
      background-color: #00ff59;
      width: 100%;
            min-height: 100vh;

      display: flex;
      justify-content: center;
      align-items: center;
    
    
    }
    .successCourseProgram{
      background-color: #00ff59;
      width: 100%;
      min-height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    #deleteModal,#deleteProfile,.signInpop{
      width: 100%;
            min-height: 100vh;

      margin: auto;
      position: fixed;
      top: 50%;
      left: 50%;
      z-index: 100;
    transform: translate(-50%,-50%);
    background-color: rgba(0, 0, 0,0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    }
    .cakeGrid{
      display: grid;
      grid-template-columns: 28% 28% 28%;
      column-gap: 30px;
      row-gap:70px;
      justify-content: center;
      text-align: center; 
      word-break: keep-all;
      margin-top: 15px;
      align-items: center;
      margin-bottom:70px;
    }
    .form-control{
    }
    .logo{
      width: 50%;
      margin-top: 20px;
      margin-bottom: 60px;
      }
    .report-card-text{
      font-family: "Reddit Mono", monospace;
      font-optical-sizing: auto;
      font-weight: 800;
      font-style: normal;
      font-size:'12px';
    }
    
    .report-card-text{
      font-family: "Reddit Mono", monospace;
      font-optical-sizing: auto;
      font-weight: 300;
      font-style: normal;
    }
    
    .nav-bg-yellow{
      background-color: #D9FE00;
      display: flex;
      align-items: center;
    }
    .logo{
    width: 13%;
    margin-left: 20px;
    cursor: pointer;
    }
    
    .nav{
      display: flex;
      justify-content: space-between;
    }
    
    .nosifer {
      font-family: "Nosifer", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 60px;
      line-height: 63px;
    }
    .paymentPop{
      width: 100%;
            min-height: 100vh;

      margin: auto;
      position: fixed;
      top: 50%;
      left: 50%;
      z-index: 100;
    transform: translate(-50%,-50%);
    background-color: rgba(0, 0, 0,0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    }
    .nav__icon {
      position: relative;
      -ms-flex: 0 0 30px;
      flex: 0 0 30px;
      width: 30px;
      height: 30px;
    width: 30px;
        height: 30px;
        margin: 0 0 0 20px;
        color: black;
        will-change: color;
        transition: color .3s ease;
        cursor: pointer;
        background: 0 0;
        border: none;}
    
        .nav__cart-count {
          font-variant-ligatures: common-ligatures;
          font-feature-settings: "liga" 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-family: National2Web-Regular, helvetica, sans-serif;
          font-weight: 700;
          font-style: normal;
          font-stretch: normal;
          font-size: .625rem;
          line-height: 1.1;
          letter-spacing: normal;
          margin: 0 auto;
          -ms-flex: 0 0 auto;
          flex: 0 0 auto;
          position: relative;
          z-index: 1;
          top: 7px;
          color: #09009a;
          border-radius: 20px;
          padding: 1px 0;
          z-index: 100;
          min-width: 14px;
          text-align: center;
          opacity: 0;
          transition: opacity .3s ease, background-color .3s ease, color .3s ease;
      }
    
        .nav__icon--cart {
        
          width: 25px;
          height: 25px;
      }
    .nav-Text{
      font-family: "Cabin Condensed", sans-serif;
      font-weight: 500;
      font-style: normal;
      font-size: 11px;
    }
    
    
  }


  @media (min-width: 0px) and (max-width: 500px) {

    #mobile{
      display: block;
    }
    #navPop{
  z-index: 1001;

      width: 100%;
            min-height: 100vh;

      margin: auto;
      position: fixed;
      top: 0%;
      left: 0%;
      
      display: flex;
      justify-content: end;
      background-color: rgba(0, 0, 0, 0.75);
      overflow: hidden;
    
    }
    
    #tablet,#desktop{
      display: none;
    }
      .adminBody{
        position: absolute;
        left: 20%;
      
        width: 80%;
        
      }
      .image-container {
        display: flex;
        overflow-x: hidden;
      }
      
      .image-container img {
        flex-shrink: 0;
        width: 100%;
        opacity: 0.5;
        transition: opacity 0.3s ease-in-out;
      }
      
      .image-container img.active {
        opacity: 1;
      }
      .header{
        font-family: "Poetsen One", sans-serif;
        font-weight: 400;
        font-style: normal;
      }
      .par{
        font-family: "Quicksand", sans-serif;
        font-optical-sizing: auto;
        font-weight: 500;
        font-style: normal;
      }
      
      .adminNav{
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 20%;
      }
      
      .report-card{
        width: 200px;
        border-radius: 20px;
        background-color: #D9FE00;
      
       
      }
      .skillClass{
      padding-top: 30px;
      padding-bottom: 30px;
      padding-left: 10px;
      padding-right: 10px;
      
      }
      .seymour-one-regular {
        font-family: "Anton", sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 20px;
      }
      .orderPop{
        overflow-y: scroll;
        position: fixed;
        top: 10vh;
        right:0 ;
        width: 100%;
        height: 90vh;
        background-color: white;
        z-index: 100;
        border-top-left-radius: 40px;
      }
      .bg-black{
  background-color: rgba(0, 0, 0,0.8);


}
      .logoAdmin{
        width: 50%;
        margin-top: 20px;
      }
      
      .your-component .react-colorful {
        height: 240px;
      }
      .your-component .react-colorful__saturation {
        border-radius: 4px 4px 0 0;
      }
      .your-component .react-colorful__hue {
        height: 40px;
        border-radius: 0 0 4px 4px;
      }
      .your-component .react-colorful__hue-pointer {
        width: 12px;
        height: inherit;
        border-radius: 0;
      }
      .skillGrid{
        display: grid;
        grid-template-columns: auto  auto auto;
        column-gap: 5px;
        row-gap: 10px;
        justify-content: center;
        text-align: center; 
        word-break: keep-all;
        margin-top: 15px;
        align-items: center;
        background-color: #D9FE00;
        color: black;
      
      }
      .navigationPop{
  z-index: 1001;
        width: 100%;
        height: 100%;
        margin: auto;
        position: fixed;
        top: 0%;
        left: 0%;
      background-color: rgba(0, 0, 0,0.75);
      }
      .IngredientsSkills{
        display: grid;
        grid-template-columns: auto  auto auto auto auto ;
        column-gap: 5px;
        row-gap: 10px;
        word-break: keep-all;
        margin-top: 10px;
        align-items: center;
        text-align: center;
      }
      .input-field-signUp:-webkit-autofill {
        caret-color: black;
        -webkit-box-shadow: 0 0 0 30px white inset; /* Override the autofill background color */
      }
      .input-field-signUp::placeholder {
        background-color: transparent; /* Set the background color of the placeholder text to transparent */
      }
      .barlow{
        font-family: "Barlow Condensed", sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 13px;
      }
      .success{
        background-color: #00ff59;
        width: 100%;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
      
      
      }
      .successCourseProgram{
        background-color: #00ff59;
        width: 100%;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      #deleteModal,#deleteProfile,.signInpop{
        width: 100%;
        height: 100%;
        margin: auto;
        position: fixed;
        top: 50%;
        left: 50%;
        z-index: 100;
      transform: translate(-50%,-50%);
      background-color: rgba(0, 0, 0,0.75);
      display: flex;
      justify-content: center;
      align-items: center;
      }
      .cakeGrid{
        display: grid;
        grid-template-columns: 40% 40% ;
        column-gap: 30px;
        row-gap:70px;
        justify-content: center;
        text-align: center; 
        word-break: keep-all;
        margin-top: 15px;
        align-items: center;
        margin-bottom:70px;
      }
      .form-control{
      }
      .logo{
        width: 50%;
        margin-top: 20px;
        margin-bottom: 60px;
        }
      .report-card-text{
        font-family: "Reddit Mono", monospace;
        font-optical-sizing: auto;
        font-weight: 800;
        font-style: normal;
        font-size: 2.8vw;
      }
      
      .report-card-text{
        font-family: "Reddit Mono", monospace;
        font-optical-sizing: auto;
        font-weight: 300;
        font-style: normal;
      }
      
      .nav-bg-yellow{
        background-color: #D9FE00;
        display: flex;
        align-items: center;
      }
      .logo{
      width: 13%;
      margin-left: 20px;
      cursor: pointer;
      }
      
      .nav{
        display: flex;
        justify-content: space-between;
      }
      
      .nosifer {
        font-family: "Nosifer", sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 60px;
        line-height: 63px;
      }
      .paymentPop{
        width: 100%;
        height: 100%;
        margin: auto;
        position: fixed;
        top: 0%;
        left: 0%;
        z-index: 100;
      background-color: rgba(0, 0, 0,0.75);
      display: flex;
      justify-content: center;
      align-items: center;
      }
      .nav__icon {
        position: relative;
        -ms-flex: 0 0 30px;
        flex: 0 0 30px;
        width: 30px;
        height: 30px;
      width: 30px;
          height: 30px;
          margin: 0 0 0 20px;
          color: black;
          will-change: color;
          transition: color .3s ease;
          cursor: pointer;
          background: 0 0;
          border: none;}
      
          .nav__cart-count {
            font-variant-ligatures: common-ligatures;
            font-feature-settings: "liga" 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-family: National2Web-Regular, helvetica, sans-serif;
            font-weight: 700;
            font-style: normal;
            font-stretch: normal;
            font-size: .625rem;
            line-height: 1.1;
            letter-spacing: normal;
            margin: 0 auto;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            position: relative;
            z-index: 1;
            top: 7px;
            color: #09009a;
            border-radius: 20px;
            padding: 1px 0;
            z-index: 100;
            min-width: 14px;
            text-align: center;
            opacity: 0;
            transition: opacity .3s ease, background-color .3s ease, color .3s ease;
        }
      
          .nav__icon--cart {
          
            width: 25px;
            height: 25px;
        }
      .nav-Text{
        font-family: "Cabin Condensed", sans-serif;
        font-weight: 500;
        font-style: normal;
        font-size: 11px;
      }
      
      
    }